import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="imgs/noodle_logo.png" alt="AI Learning"/>
      </div>
      <div className="navbar-right">
        <ul className="navbar-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
        </ul>
        {/* <button className="navbar-button">Sign Up for Emails</button> */}
      </div>
    </nav>
  );
};

export default Navbar;