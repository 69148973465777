import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <p>Neat. ❤️</p>
        {/* Add more content as needed */}
      </div>
    </div>
  );
};

export default About;