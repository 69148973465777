import React, { useState } from 'react';
import './Home.css';

const Home = () => {
 

  return (
    <div className="home">
      Snurpin'
    </div>
  );
};

export default Home;

